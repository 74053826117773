import React, { useState } from "react";
import supabase from "../services/supabase";
import "../App.css";
import logo from "./IPTalonsLogo.png";

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const handleLogin = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: loginEmail,
      password: loginPassword
    });
    if (error) console.error('Error logging in:', error.message);
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-logo-container">
          <img src={logo} alt="Sitruna Logo" className="login-logo" />
        </div>
        <h2 className="login-title">Sign in to your account</h2>
        <div className="login-form-container">
          <div className="login-form-card">
            <input
              id="email"
              type="email"
              className="login-input"
              placeholder="Email"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
            />
            <input
              id="password"
              type="password"
              className="login-input"
              placeholder="Password"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
            />
          </div>
          <button className="login-button" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
      <div className="login-footer">
        &copy; 2024 IPTalons, Inc. All rights reserved.
      </div>
    </div>
  );
};

export default Login;
